export const prepareActivity = ({ activity = {}, type, deal_id, contact_id }) => {
  const { date, time = '' } = activity;

  try {
    if (!date) {
      throw new Error("Date is not defined or empty.");
    }
  
    const dateObject = new Date(date);
    if (time) {
      const splitTime = time.split(':');
      const hours = parseInt(splitTime[0]) || 0;
      const minutes = parseInt(splitTime[1]) || 0;
  
      dateObject.setHours(hours);
      dateObject.setMinutes(minutes);
    }
  
    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid date or time format.");
    }
  
    const timezoneOffsetHours =0;
    dateObject.setHours(dateObject.getHours() + timezoneOffsetHours);
  
    const isoString = dateObject.toISOString();
  
    delete activity.time;
  
    const data = {
      ...activity,
      date: isoString,
      type,
    };
  
    if (deal_id) {
      data.deal_id = deal_id
    }

    if (contact_id) {
      data.contact_id = contact_id
    }

    return data

  } catch (error) {
    console.error("Error:", error.message);
  }



}