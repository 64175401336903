import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ActivityButtonGroup.scss';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, CustomInput
} from 'reactstrap';
import moment from 'moment';
import plusIcon from '../../images/icn-plus.svg';
import ActivityType from '../ActivityType';
import ModalConfirm from '../ModalConfirm';
import editIcon from '../../images/icn-edit.svg';
import { useAuth } from '../Auth';
import { fetchUsers } from '../../lib/fetch/users';
import { ButonTrash } from '../ButtonTrash/ButtonTrash';
import DateInput from '../DateInput';

function ActivityModal({ open, toggle, onSubmit, activity, user, type, onDeleteActivity } ) {

  
  const [t] = useTranslation('ACTIVITIES');
  const [tCommon] = useTranslation('COMMON');
  const { id } = activity || {};

  const [{ token } = {}] = useAuth();
  const [users, setUsers] = useState([]);

  const [form = {
    user_id: user ?? activity?.user_id ?? null,
    done: activity?.done === 1,
    description: activity?.description,
    date: moment(activity?.date).format('YYYY-MM-DD'),
    time: moment(activity?.date).format('HH:mm')
  }, setForm] = useState();

  const updateValue = (name, value) => {
    setForm({ ...form, [name]: value });
  }

  const fetchOptions = useCallback(async () => {
    if (token) {
      try {
        const { data } = await fetchUsers({ token, limit: 1000 });
        setUsers(data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    }
  }, [token]);

  useEffect(() => {
    fetchOptions();
    // eslint-disable-next-line
  }, [token]);

  const prepareData = (e) => {
    e.preventDefault();
    const data = { ...form, done: form.done ? 1 : 0 };
    onSubmit(data);
  };

  return (
    <Modal isOpen={open} fade={false} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t(id ? 'UPDATE_ACTIVITY' : 'REGISTER_NEW_ACTIVITY')} <br />
        <ActivityType type={type} />
      </ModalHeader>
      <ModalBody>
        <Form inline className="m-b-30" onSubmit={prepareData}>
          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <Label for="Date" className="mr-2">{t('DAY')}:</Label>
            <DateInput
              name="date"
              disablePopup={true}
              value={form.date}
              onChange={value => updateValue('date', value)}
            />
          </FormGroup>
          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <Label for="Time" className="">{t('HOURS')}:</Label>
            <Input
              type="time"
              name="time"
              className="mr-4"
              value={form.time}
              onChange={(e) => updateValue('time', e.target.value)}
            />
          </FormGroup>

          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <Label className="mr-2">{t('COMMON:USER')}</Label>
            <CustomInput
              type="select"
              name="user_id"
              id="user_id"
              value={form?.user_id}
              onChange={e => updateValue('user_id', e.target.value)}
            >
              <option value={0}>{tCommon('NO_VALUE_SELECTED')}</option>
              {
                users?.map(({ id, first_name, last_name }) => (
                  <option value={id} key={id}>{first_name} {last_name}</option>
                ))
              }
            </CustomInput>
          </FormGroup>
          
          <FormGroup className="mb-4" style={{ width: '50%' }}>
            <CustomInput
              className="done-checkbox"
              name="done"
              defaultChecked={form.done}
              type="checkbox"
              id="done"
              onChange={(e) =>  updateValue('done', e.target.checked ? 1 : 0)}
              label={t("COMMON:SET_AS_DONE")}
            />
          </FormGroup>

          <FormGroup className="mb-4 form-description">
            <Label className="mr-2">{t('COMMON:DESCRIPTION')}</Label>
            <Input
              type="textarea"
              name="description"
              className="mx-sm-4"
              value={form.description}
              onChange={(e) => updateValue('description', e.target.value)}
            />
          </FormGroup>

          <ModalFooter className="cont-btn-modalform">
            {
              id && typeof onDeleteActivity === 'function' &&
              <ModalConfirm
                text={t('DELETE_REQUEST')}
                onConfirm={onDeleteActivity}
                className="user-delete-button"
              >
                <ButonTrash />
              </ModalConfirm>
            }
            <Button type="submit" color="primary">
              <img src={id ? editIcon : plusIcon} alt="" />
              {t(id ? 'UPDATE_ACTIVITY' : 'ADD_ACTIVITY')}
            </Button>
            <Button color="secondary" onClick={toggle}>{t('COMMON:CANCEL')}</Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ActivityModal;
